import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { map, first } from 'rxjs';

import { UserRole } from '../enums/user-role';
import { UserProfile } from '../models/user-profile';
import { UserAuthService } from '../services/user-auth.service';
import { routePaths } from '../utils/route-paths';

/** Guard prevents a current user from accessing Admin Panel if he doesn't have necessary permissions. */
export function adminPanelGuard(): CanMatchFn {
  return () => {
    const userService = inject(UserAuthService);
    const router = inject(Router);
    return userService.currentUser$.pipe(
      map(user => {
        if (user !== null && (user.isGlobalAdmin === true || checkUserHasAllowedRole(user))) {
          return true;
        }
        return router.createUrlTree(routePaths.accessError);
      }),
      first(),
    );
  };
}

/**
 * Checks if the user has allowed role.
 * @param user User.
 */
function checkUserHasAllowedRole(user: UserProfile): boolean {
  const allowedRoles = [UserRole.OrganizationAdmin, UserRole.UserAdmin, UserRole.Developer];
  return user.organizations.some(org => org.roles.some(role => allowedRoles.includes(role)));
}
