import { Routes } from '@angular/router';

import { UserRole } from '@atsdart/common/core/enums/user-role';
import { adminPanelGuard } from '@atsdart/common/core/guards/admin-panel.guard';
import { restrictUserRoleGuard } from '@atsdart/common/core/guards/restrict-user-role.guard';
import { unauthorizedGuard } from '@atsdart/common/core/guards/unauthorized.guard';

/** Routes. */
export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.routes').then(m => m.authRoutes),
  },
  {
    path: 'register',
    loadChildren: () => import('./features/register/register.routes').then(m => m.registerRoutes),
  },
  {
    path: 'personal',
    loadChildren: () => import('./features/confirm-new-email/confirm-new-email.routes').then(m => m.confirmNewEmailRoutes),
  },
  {
    path: 'access-error',
    loadComponent: () => import('./features/shared/components/app-access-error-page/app-access-error-page.component')
      .then(m => m.AppAccessErrorPageComponent),
  },
  {
    path: '',
    canMatch: [unauthorizedGuard(), adminPanelGuard()],
    loadComponent: () => import('./features/shared/components/web-app-layout/web-app-layout.component').then(m => m.WebAppLayoutComponent),
    children: [
      {
        path: 'authority-management',
        loadChildren: () => import('./features/authority-management/authority-management.routes').then(m => m.authorityManagementRoutes),
        canMatch: [restrictUserRoleGuard()],
        data: {
          userRoles: [UserRole.UserAdmin, UserRole.OrganizationAdmin],
          breadcrumb: {
            label: 'Authority Management',
          },
        },
      },
      {
        path: 'organizations',
        loadChildren: () => import('./features/organizations/organizations.routes').then(m => m.organizationsRoutes),
        canMatch: [restrictUserRoleGuard()],
        data: {
          breadcrumb: 'Organizations',
        },
      },
      {
        path: 'organization-account-settings',
        loadChildren: () => import('./features/org-account-settings/org-account-settings.routes').then(m => m.orgAccountSettingsRoutes),
        canMatch: [restrictUserRoleGuard()],
        data: {
          userRoles: [UserRole.UserAdmin, UserRole.OrganizationAdmin],
          breadcrumb: {
            label: 'Organization Account Settings',
          },
        },
      },
      {
        path: 'resources',
        loadChildren: () => import('./features/components/components.routes').then(m => m.componentsRoutes),
        data: {
          breadcrumb: {
            label: 'Resources',
          },
        },
      },
      {
        path: 'template-admin',
        loadChildren: () => import('./features/template-admin/template-admin.routes').then(m => m.templateAdminRoutes),
        data: {
          breadcrumb: {
            label: 'Template Admin',
          },
        },
      },
      { path: '', redirectTo: 'authority-management', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'authority-management' },
];
