import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { first, map } from 'rxjs/operators';

import { UserAuthService } from '../services/user-auth.service';
import { routePaths } from '../utils/route-paths';

/** Guard prevents user from accessing if a user is not logged in. */
export function unauthorizedGuard(): CanMatchFn {
  return () => {
    const userService = inject(UserAuthService);
    const router = inject(Router);
    const url = router.getCurrentNavigation()?.initialUrl.toString();
    return userService.isAuthorized$.pipe(
      map(isAuthorized => {
        if (isAuthorized) {
          return true;
        }

        return router.createUrlTree(routePaths.login, {
          queryParams: url ? { next: url } : undefined,
        });
      }),
      first(),
    );
  };
}
